@font-face {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/static/fonts/Gilroy/Gilroy-Regular.woff2") format('woff2');
}

@font-face {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("/static/fonts/Gilroy/Gilroy-Medium.woff2") format('woff2');
}

@font-face {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("/static/fonts/Gilroy/Gilroy-SemiBold.woff2") format('woff2');
}

@font-face {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/static/fonts/Gilroy/Gilroy-Bold.woff2") format('woff2');
}

@font-face {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("/static/fonts/Gilroy/Gilroy-ExtraBold.woff2") format('woff2');
}

@font-face {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("/static/fonts/Gilroy/Gilroy-Black.woff2") format('woff2');
}
